import { RegisterService } from './../register.service';
import { RegisterRequest } from './../register-request';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  alert: boolean = false;
  processing: boolean = false;
  alertStatus: boolean = false;
  myForm: FormGroup;
  constructor(private fb: FormBuilder, private registerService: RegisterService,
    ) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['',[Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      company: ['', Validators.required],
      jobTitle: ['', Validators.required]
    });
  }

  get firstName() { return this.myForm.get('firstName'); }
  get lastName() { return this.myForm.get('lastName'); }
  get email() { return this.myForm.get('email'); }
  get company() { return this.myForm.get('company'); }
  get jobTitle() { return this.myForm.get('jobTitle'); }

  submitRequestToRegister(){
    console.log("Regtster form status: ", this.myForm);
    this.processing = true;
    if (!this.myForm.valid) {
      return false;
    }
    const data = this.myForm.value
    const REGISTER_REQUEST: RegisterRequest = {
      first_name: data["firstName"],
      last_name: data["lastName"],
      email_address: data["email"],
      company: data["company"],
      job_title: data["jobTitle"]
    };
    this.registerService.registerRequest(REGISTER_REQUEST).subscribe(
      (res) => {
        this.myForm.reset();
        if (res.statusCode == 200) {
          this.alertStatus = true;
          this.alert = true;
        }
        else {
          this.alertStatus = false;
          this.alert = true;
        }
      }
    );
    return
  }
}
