<style>
  #wrapper {
    height: 100%;
  }
</style>
<div id="wrapper">

  <!-- Main view/routes wrapper-->
  <router-outlet></router-outlet>

</div>