import { Component, OnInit } from '@angular/core';
import { PRODUCT_DETAIL, PRODUCT_MARKETING_LINKS } from '../product-details';
import { DemoRequest } from '../demo-request';
import { RequestDemoService } from '../request-demo.service';
import { KeycloakService } from 'keycloak-angular';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [RequestDemoService]
})
export class HomeComponent implements OnInit {
  public showAllProducts: boolean = true;
  public selectedProduct: string = '';
  public submitted: boolean = false;
  myForm: FormGroup;
  noApplicationIsSelected: boolean = false;
  isProcesssing = false;
  applications= [
    {
      name: 'Connexus',
      value: 'Connexus'
    },
    {
      name: 'Crash Hotspots',
      value: 'Crash Hotspots'
    },
    {
      name: 'Curb Management',
      value: 'Curb Management'
    },
    {
      name: 'Data Hub',
      value: 'Data Hub'
    },
    {
      name: 'Eventflow',
      value: 'Eventflow'
    },
  ];
  constructor(private fb: FormBuilder, private requestDemoService: RequestDemoService,
    private keycloakService: KeycloakService) { 
    }
  
  ngOnInit() {
    this.myForm = this.fb.group({
      // applicationsSelected: this.createApplicationsFormInput(this.applications),
      applicationsSelected: this.fb.array([], [Validators.required]),
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['',[Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      company: ['', Validators.required],
      jobTitle: ['', Validators.required],
      comments: ['', Validators.required]
    });
  }
  
  get applicationsSelected() { return this.myForm.get('applicationsSelected'); }
  get firstName() { return this.myForm.get('firstName'); }
  get lastName() { return this.myForm.get('lastName'); }
  get email() { return this.myForm.get('email'); }
  get company() { return this.myForm.get('company'); }
  get jobTitle() { return this.myForm.get('jobTitle'); }
  get comments() { return this.myForm.get('comments'); }
  onCheckboxChange(e) {
    const checkArray: FormArray = this.myForm.get('applicationsSelected') as FormArray;
    this.noApplicationIsSelected = false;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  createApplicationsFormInput(apps: any) {
    const arr = [];
    for (var i=0; i<apps.length; i++) {
      arr.push(false);
    }
    return new FormArray(arr);
  }

  logout(): void {
    this.keycloakService.logout();
  }

  render(product_name: string): void {
    this.selectedProduct = product_name;
    this.showAllProducts = false;
  }

  prettify(value: string) {
    return value
  }

  submitRequestToDemo(){
    if (!this.myForm.valid) {
      if (this.myForm.value["applicationsSelected"].length == 0) {
        this.noApplicationIsSelected = true;
      }
      return false;
    }

    
    this.isProcesssing = true;
    const data = this.myForm.value
    const DEMO_REQUEST: DemoRequest = {
      first_name: data["firstName"],
      last_name: data["lastName"],
      email_address: data["email"],
      company: data["company"],
      job_title: data["jobTitle"],
      applications: data["applicationsSelected"],
      comments: data["comments"],
      time_stamp: new Date().toUTCString().toString()
    };
    this.requestDemoService.sendDemoRequest(DEMO_REQUEST).subscribe(
      (res) => {
        if (res.statusCode == 200) {
          this.submitted = true;
        }
      }
    );
    return
  }

  showMainPage() {
    this.showAllProducts = true;
    this.selectedProduct = '';
  }

  getProductTitle() {
     return this.selectedProduct.replace("-", " ");
  }

  getProductDetail() {
    return PRODUCT_DETAIL[this.selectedProduct]
  }

  downloadMarketingMaterials() {
    PRODUCT_MARKETING_LINKS[this.selectedProduct].forEach(element => {
      window.open(element, "_blank");
    });
  }

  isMarketingMaterialAvailable() {
    if(this.selectedProduct in PRODUCT_MARKETING_LINKS)
      return true
    else  
      return false
  }

}
