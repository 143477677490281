import { DemoRequest } from './demo-request';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RequestDemoService {
  private URL = 'https://43x3ulhpua.execute-api.us-east-1.amazonaws.com/prod/request-demo';
  constructor(private http: HttpClient) { }

  sendDemoRequest(req: DemoRequest): Observable<any> {
    const header = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }

    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(header), 
    };
    let response = this.http.post(this.URL, 
      {
        'first_name': req.first_name,
        'last_name': req.last_name,
        'email_address': req.email_address,
        'company': req.company,
        'job_title': req.job_title,
        'applications': req.applications,
        'comments': req.comments,
        'timestamp': req.time_stamp
      },
      requestOptions)
    return response
  }
}
