export const PRODUCT_DETAIL= {
    "connexus": "Connexus uses the connected vehicle data from the Road Side Units (RSUs) for helping authorities to detect unsafe hotspots, location of road hazards such as potholes and other characteristics that will contribute towards proactively reducing the risk of accidents on the road network.",
    "eventflow": "Eventflow uses machine learning to automatically identify and predict where most popular events helping transit and traffic agencies to pro-actively prepare for road and public transport conditions. This is a cloud solution which provides a userfriendly interface to visualize the existing public transport options and events indicating their popularity so that the agencies can plan around the events.",
    "curb-management": "An online platform that has an ability to ingest the curb asset data from an external source and have the rules and restrictions of the curb being visualized in a map. The system allows the user to modify the rules and restriction of the curb, enabling the visualization of new rules and can broadcast via APIs. It also provides a dashboard giving insights on revenue and other different statistics.",
    "crash-hotspots": "Cloud-based application to help transportation engineers and safety planners to synthesize the factors that influence the traffic incidents and roadway safety. It also provides powerful insights through a dynamic and user-friendly interface that analyzes most dangerous areas for all type of accidents in an area. It enables cities to implement the mitigation strategies and also recommends proven countermeasures to proactively reduce number of incidents in identified areas.",
    "data-hub": "The ITS Data Hub gives cities a cloud-based platform that enables the sharing of traffic and transportation data with partners, suppliers, academia and existing systems through open Application Programming Interfaces (APIs) that can be managed based on the city's requirements. This provides the cities to leverage the data in creating new applications."
}

export const PRODUCT_MARKETING_LINKS = {
    "connexus": [
        "https://dl-marketing-material.s3.amazonaws.com/Connexus/Connexus+-+Presentation.pdf",
        "https://dl-marketing-material.s3.amazonaws.com/Connexus/ITS+Digital+Lab+Case+Study+-+THEA+AI+Queue+Length+Detection+v3.pdf"
    ],
    "eventflow": [
        "https://dl-marketing-material.s3.amazonaws.com/Eventflow/Eventflow+-+Final+Flyer_v4.pdf"
    ],
    "data-hub": [
        "https://dl-marketing-material.s3.amazonaws.com/Data+Hub/ITS+Data+Hub+-+Flyer+v2.pdf",
        "https://dl-marketing-material.s3.amazonaws.com/Data+Hub/SIemens+ITS+Data+Hub+-+Infographic+v2.pdf"
    ]
}