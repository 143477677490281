import { environment } from './../../environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { Injectable, APP_INITIALIZER } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  // protected authenticated: boolean;
  constructor(private authService: KeycloakService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> | boolean {
      // console.log("I am in, ", this.authService.isLoggedIn);
      // var status: boolean = false;
      // var initializeKeycloak = new Promise(function (resolve, reject) {
      //   status = this.keycloakInit.bind(this);
      // });

      // return status
      // initializeKeycloak.then(function(){
      //   return this.authService.isLoggedIn;
      // });

      // this.authService.init({
      //   config: {
      //     url: environment.keycloak.url,
      //     realm: environment.keycloak.realm,
      //     clientId: environment.keycloak.clientId
      //   },
      //   loadUserProfileAtStartUp: false,
      //   initOptions: {
      //     onLoad: 'login-required',
      //     checkLoginIframe: true
      //   }
      // }).then(function() {
      //   return this.authService.isLoggedIn();
      // });

      this.authService.init({
        config: {
          url: environment.keycloak.url,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId
        },
        loadUserProfileAtStartUp: false,
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: true
        }
      })

      if (this.authService.isLoggedIn) {
        return true;
      }
      else {
        return false;
      }
  }
  
  // private keycloakInit(){
  //   this.authService.init({
  //     config: {
  //       url: environment.keycloak.url,
  //       realm: environment.keycloak.realm,
  //       clientId: environment.keycloak.clientId
  //     },
  //     loadUserProfileAtStartUp: false,
  //     initOptions: {
  //       onLoad: 'login-required',
  //       checkLoginIframe: true
  //     },
  //     bearerExcludedUrls: []
  //   });
  //   if (this.authService.isLoggedIn) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }
}
