<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style lang="scss">
  .main {
    z-index: 1000;
    font-family: "Siemens Sans Roman", Arial, helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-template-rows: 4.475rem auto 32px;
    grid-template-areas:
      "top-navbar top-navbar top-navbar top-navbar top-navbar top-navbar"
      "body body body body body body"
      "footer-copyright footer-copyright footer-copyright footer-links footer-links footer-links";
    width: 100vw;
    height: 100%;
  }
  .top-navbar {
    position: fixed;
    margin: 0 auto;
    width: 100%;
    color: #fff;
    top: 0;
    transition: top .7s ease;
    z-index: 100;
    background: linear-gradient(180deg,#000028,#000a2f);
    box-shadow: none;
    flex: 0 1 auto;
  }

  .product-container {
    padding: 2% 5%;
  }

  .header-logo {
    margin: 0 15px 0 0 ;
    width: 155px;
    display: inline-block;
    text-transform: uppercase;
  }

  .navbar-brand {
    font-family: "Siemens Sans Bold", Arial, helvetica, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    color: white;
  }

  @font-face {
    font-family: "Siemens Sans Bold";
    src: url("assets/fonts/SiemensSans_Prof_Bold.woff2") format("woff2"),
      url("assets/fonts/SiemensSans_Prof_Bold.woff") format("woff"),
      url("assets/fonts/SiemensSans_Prof_Bold.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Siemens Sans Roman";
    src: url("assets/fonts/SiemensSans_Prof_Roman.woff2") format("woff2"),
      url("assets/fonts/SiemensSans_Prof_Roman.woff") format("woff"),
      url("assets/fonts/SiemensSans_Prof_Roman.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
  }

  .navbar {
    background: linear-gradient(180deg,#000028,#000a2f);
  }

  .footer-copyright {
    min-height: 32px;
    max-height: 32px;
    grid-area: footer-copyright;
    font-size: 14px;
  }

  .footer-links {
    min-height: 32px;
    max-height: 32px;
    font-size: 14px;
    grid-area: footer-links;
    justify-content: flex-end;
  }

  .footer-links * a {
    text-decoration: none;
    color: white;
  }

  .footer-links * a:hover {
    text-decoration: none;
    color: white;
  }

  .footer {
    padding-left: 15px;
  }

  .product {
    grid-area: body;
  }


  .nav-link {
    display: inline-block;
    color: white;
  }

  /* .product .card {
    max-height: 499px;
  }

  .product .card-img-top {
    max-height: 335px;
  } */

  /* .eventflow {
    background-image: url('../assets/images/eventflow.png');
  }

  .data-hub {
    background-image: url('../assets/images/data-hub.png');
  }

  .curb-management {
    background-image: url('../assets/images/curb-management.png');
  }

  .crash-hotspots {
    background-image: url('../assets/images/crash-hotspots.png');
  }

  .connexus {
    background-image: url('../assets/images/connexus.png');
  } */

  .product .card {
    cursor: pointer;
  }
  .product .card-body {
    color: white;
    background-color: #000028;
    text-align: left;
    /* padding: 0.8rem !important; */
  }

  .product .card-text {
    font-weight: normal;
  }

  .product .card-body {
    font-size: 1rem;
  }
  .product .card-title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
    margin: 0 !important;
  }

  .product-card {
    min-width: 25rem;
    max-width: 25rem;
  }

  .card-img-top {
    width: 100%;
    height: auto;
    /* object-fit: cover; */
  }

  .product .card:hover {
    transform: scale(1.02);
    transition: all 0.3s ease-in;
    opacity: 1.2;
  }

  .product-detail {
    grid-area: body;
    text-align: left;
    margin: 1% 10% 0% 10% !important;
    padding-top: 5%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "back-link"
      "card";
    color: #ffff;
  }

  .product-detail-header-img {
    width: 100%;
    height: 30vh;
    object-fit: cover;
  }

  .product-detail .card-title {
    text-transform: capitalize;
    font-family: "Siemens Sans Bold", Arial, helvetica, sans-serif;
  }

  .product-detail .card-body {
    font-family: "Siemens Sans Roman", Arial, helvetica, sans-serif;
  }

  .back-link {
    grid-area: back-link;
  }

  .product-image {
    grid-area: product-image;
  }

  .product-description {
    grid-area: product-description;
  }

  .product-detail .card {
    background-color: #000028;
    color: white;
  }
  /* .product-detail .card-body {
    text-align: initial;
  } */

  .request-demo {
    background: linear-gradient(90deg,#00ffb9,#00e6dc);
    border-radius: 0 !important;
    margin-top: 3%;
    color: black;
    padding: 20px 60px 20px 60px;
  }

  .submit-request-demo {
    background: #008484;
    color: white;
    font-family: "Siemens Sans Bold";
  }

  .request-demo:hover {
    background: #00ffb9;
  }

  .details {
    background: linear-gradient(90deg,#00ffb9,#00e6dc);
    border-radius: 0 !important;
  }

  .actions {
    margin-top: 2%;
  }

  .more-details-link {
    color: #008484;
  }

  .back-to-home {
    color: #008484;
  }
  .bi-file-earmark-text {
    color: #008484;
  }

  .back-link-icon {
    color: #008484;
  }

  #selectApplication {
    width: 100%;
  }

  .modal-body {
    text-align: initial;
  }

  .cross-validation-error input {
    border-left: 5px solid red;
  }

  .modal-content {
    background: #ebf0f5;
  }
</style>

<!-- Toolbar -->
<div class="main">
  <div class="top-navbar">
    <nav class="navbar navbar-expand-lg">
      <a href="#" class="navbar-brand" (click)="showMainPage()"> ITS Digital Lab Apps</a>
        <div class="navbar-collapse">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown">
              <img
                class="header-logo navbar-brand"
                alt="SIEMENS"
                src="../assets/sie-logo-white-rgb.png"
              />
              <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                <path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                <path fill-rule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
              </svg>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#" (click)="logout()">Sign Out</a>
              </div>
            </li>
          </ul>
        </div>
    </nav>
  </div>

  <div class="product" *ngIf="showAllProducts; else elseBlock" >
    <div class="product-container">
    
    <div class="row mt-1 mb-4">
      <div class="col h-100">
        <div class="card border-0" (click)="render('connexus')">
            <div class="tile-img">
              <img class="card-img-top" src="../assets/images/connexus.jpg" alt="Card image cap">
            </div>
            <div class="card-body">
              <h5 class="card-title">Connexus</h5>
              <p class="card-text">Innovative approach to enhance safety for Connected Vehicles.</p>
            </div>
        </div>
      </div>
      <div class="col h-100">
        <div class="card border-0" (click)="render('crash-hotspots')">
            <div class="tile-img">
              <img class="card-img-top" src="../assets/images/crash-hotspots.jpg" alt="Card image cap">
            </div>
            <div class="card-body h-10">
              <h5 class="card-title">Crash Hotspots</h5>
              <p class="card-text">Incident prediction and counter-measures recommendation.</p>
            </div>
        </div>
      </div>
      <div class="col h-100">
        <div class="card border-0" (click)="render('curb-management')">
            <div class="tile-img">
              <img class="card-img-top" src="../assets/images/curb-management.jpg" alt="Card image cap">
            </div>
            <div class="card-body h-10">
              <h5 class="card-title">Curb Management</h5>
              <p class="card-text">Online platform to help cities manage their curbs.</p>
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card border-0" (click)="render('data-hub')">
            <img class="card-img-top" src="../assets/images/data-hub.jpg" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title">Data Hub</h5>
              <p class="card-text">Open data exchange platform for traffic and transportation.</p>
            </div>
        </div>
      </div>
      <div class="col">
        <div class="card border-0" (click)="render('eventflow')">
            <img class="card-img-top" src="../assets/images/eventflow.jpg" alt="Card image cap">
            <div class="card-body h-40">
              <h5 class="card-title">Eventflow</h5>
              <p class="card-text">Event Prediction for intelligent urban mobility systems.</p>
            </div>
        </div>
      </div>
    </div>
  
    </div>
  </div>
  <ng-template #elseBlock>
    <div class="product-detail">
      <div class="product-detail-container">
        <div class="back-link" (click)="showMainPage()">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="back-link-icon bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
          </svg>
          <a href="#" class="back-to-home" role="button"  >Back</a>
        </div>
  
        <div class="card">
          <img class="card-img-top product-detail-header-img" src="../assets/images/{{selectedProduct}}.jpg" alt="Card image cap">
          <div class="card-body">
            <h5 class="card-title">{{ getProductTitle() }}</h5>
            <p class="card-text">{{ getProductDetail() }}</p>
            <div class="download-links" *ngIf="isMarketingMaterialAvailable()">
              <a target="_blank" class="more-details-link" role="button" (click)="downloadMarketingMaterials()">Brochure</a>
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-file-earmark-text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z"/>
                <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
              </svg>
            </div>
            <div style="text-align: center;">
              <a href="#" class="btn btn-primary request-demo" data-toggle="modal" data-target="#exampleModal">Request a Demo</a>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Request a demo</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" [hidden]="submitted || isProcesssing">
            <form [formGroup]="myForm" (ngSubmit)="submitRequestToDemo()">
              <div class="form-group">
                <div class="form-check form-check-inline" *ngFor="let app of applications; let i=index">
                  <input class="form-check-input" type="checkbox" id="{{ app.name }}" value="{{app.value}}"
                  (change)="onCheckboxChange($event)">
                  <label class="form-check-label" for="{{ app.name }}">{{ app.name }}</label>
                </div>
                <div *ngIf="noApplicationIsSelected"
                    class="alert alert-danger">

                  <div *ngIf="noApplicationIsSelected">
                    Select at least one application.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="firstName">First Name</label>
                <input formControlName="firstName" name="firstName" class="form-control" id="firstName" placeholder="First Name"
                >
                <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                    class="alert alert-danger">

                  <div *ngIf="firstName.errors.required">
                    First Name is required.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="lastName">Last Name</label>
                <input formControlName="lastName" class="form-control" id="lastName" name="lastName" placeholder="Last Name"
                appForbiddenName="bob">
                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                    class="alert alert-danger">

                  <div *ngIf="lastName.errors.required">
                    Last Name is required.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="email">Email address</label>
                <input formControlName="email" name="email" class="form-control" id="email" placeholder="Email address"
                >

                <div *ngIf="email.invalid && (email.dirty || email.touched)"
                    class="alert alert-danger">

                  <div *ngIf="email.errors.required">
                    Email address is required.
                  </div>
                  <div *ngIf="email.errors.pattern">
                    Enter a valid email address.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="company">Company</label>
                <input formControlName="company" class="form-control" name="company" id="company" placeholder="Company"
                >
                <div *ngIf="company.invalid && (company.dirty || company.touched)"
                    class="alert alert-danger">

                  <div *ngIf="company.errors.required">
                    Company is required.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="jobTitle">Job Title</label>
                <input formControlName="jobTitle" class="form-control" id="jobTitle" name="jobTitle" placeholder="Job Title"
                >
                <div *ngIf="jobTitle.invalid && (jobTitle.dirty || jobTitle.touched)"
                    class="alert alert-danger">

                  <div *ngIf="jobTitle.errors.required">
                    Job Title is required.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="comments">How do you see the solution(s) helping you?</label>
                <textarea formControlName="comments" maxlength="500" class="form-control" placeholder="Leave a comment here" id="comments" style="height: 100px"></textarea>
                <div *ngIf="comments.invalid && (comments.dirty || comments.touched)"
                    class="alert alert-danger">

                  <div *ngIf="comments.errors.required">
                    Information is required.
                  </div>
                </div>
              </div>
              <button type="submit" class="justify-content-center btn btn-primary submit-request-demo" [disabled]="myForm.invalid">Submit</button>
            </form>
          </div>
          <div class="modal-body" [hidden]="!isProcesssing || submitted">
            <div class="text-center">
              <div class="spinner-grow text-primary" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
          </div>
          <div class="modal-body" [hidden]="!submitted">
              <p>Request for the demo is submitted. We will get back to you to assist with your request.</p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div
    class="footer-copyright d-flex bg-dark text-light align-items-center px-3 py-2"
  >
    © Siemens Mobility 2020
  </div>
  <div
    class="footer-links d-flex bg-dark text-light align-items-center px-3 py-2"
  >
    <div class="footer">
      <a href="http://www.siemens.com/digital_id_en" target="_blank"
        >Digital ID</a
      >
    </div>
    <div class="footer">
      <a href="http://www.siemens.com/terms_of_use" target="_blank"
        >Terms of Use</a
      >
    </div>
    <div class="footer">
      <a
        href="https://new.siemens.com/global/en/general/legal.html#CookieNotice"
        target="_blank"
        >Cookie Notice</a
      >
    </div>
    <div class="footer">
      <a href="http://www.siemens.com/privacy" target="_blank"
        >Privacy Policy</a
      >
    </div>
    <div class="footer">
      <a href="http://www.siemens.com/corporate_info" target="_blank"
        >Corporate Information</a
      >
    </div>
  </div>
</div>
