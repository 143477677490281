<style lang="scss">
    .register-container {
        display: grid;
        grid-template-columns: 0.5fr 1.5fr 0.5fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas:
            ". main .";
        height: 100vh;
        width: 100vw;
        background: url("assets/images/dl-bg.jpg") no-repeat center center fixed;
        background-size: cover;
    }

    .page-title {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 40px;
        /* text-transform: uppercase; */
        letter-spacing: 3px;
        line-height: 1.2em;
        padding: 40px 10px 20px;
        white-space: normal;
        color: white;
        text-align: center;
    }
    .form-wrapper {
        position: absolute;
        left: 37%;
        width: 25%;
        background-color: white;
        padding:20px 40px 30px 40px;
    }

    .myForm {
        padding: 2%;
    }

    .form-title {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
    }

    .form-label {
        font-weight: 600;
    }

    .register-btn {
        width: 100%;
    }

    .back-to-login {
        padding-bottom: 2%;
        font-size: 0.75rem;
    }

    .form-wrapper .alert-dismissible {
        padding-right: 2rem !important;
    }
    
    .form-wrapper .alert-dismissible .close {
        padding: .75rem 1.0rem !important;
    }
    /* #register {
    background: url("assets/images/keycloak-bg.png") no-repeat center center fixed;
    } */

</style>
<div class="register-container">
    <div></div>
    <div class="main">
        <div class="page-title">
            ITS Digital Lab Portal
        </div>
        <div class="form-wrapper">
            <div class="form-title">
                Register
            </div>
            <div *ngIf="alert" class="alert alert-dismissible fade show" [className]="alertStatus ? 'alert alert-dismissible fade show alert-success' : 'alert alert-dismissible fade show alert-danger'" role="alert">
                <strong>Success!</strong> Registration is successful. You will be contacted with login details.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form class="myForm" [formGroup]="myForm" (ngSubmit)="submitRequestToRegister()">
                <div class="form-group">
                    <label class="form-label" for="firstName">First Name</label>
                    <input formControlName="firstName" name="firstName" class="form-control" id="firstName" placeholder="First Name"
                    >
                    <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                        class="alert alert-danger">

                        <div *ngIf="firstName.errors.required">
                            First Name is required.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="lastName">Last Name</label>
                    <input formControlName="lastName" class="form-control" id="lastName" name="lastName" placeholder="Last Name"
                    appForbiddenName="bob">
                    <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                        class="alert alert-danger">
                        <div *ngIf="lastName.errors.required">
                            Last Name is required.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="email">Email address</label>
                    <input formControlName="email" name="email" class="form-control" id="email" placeholder="Email address"
                    >
                    <div *ngIf="email.invalid && (email.dirty || email.touched)"
                        class="alert alert-danger">
                        <div *ngIf="email.errors.required">
                            Email address is required.
                        </div>
                        <div *ngIf="email.errors.pattern">
                            Enter a valid email address.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="company">Company</label>
                    <input formControlName="company" class="form-control" name="company" id="company" placeholder="Company"
                    >
                    <div *ngIf="company.invalid && (company.dirty || company.touched)"
                        class="alert alert-danger">

                        <div *ngIf="company.errors.required">
                            Company is required.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="jobTitle">Job Title</label>
                    <input formControlName="jobTitle" class="form-control" id="jobTitle" name="jobTitle" placeholder="Job Title"
                    >
                    <div *ngIf="jobTitle.invalid && (jobTitle.dirty || jobTitle.touched)"
                        class="alert alert-danger">
                        <div *ngIf="jobTitle.errors.required">
                            Job Title is required.
                        </div>
                    </div>
                </div>
                <a href="https://portal.itsdigitallab.com" class="back-to-login">Back to login</a>
                <!-- <div onclick="location.href='https://portal.itsdigitallab.com';" class="back-to-login" style="cursor:pointer;">Back to Login</div> -->
                <button type="submit" class="justify-content-center btn btn-primary register-btn">Register</button>
            </form>
        </div>
    </div>
    <div></div>
</div>